@import "global";

.mooveez {

  &__letter {
    max-width: 630px;
    left: -150px;
    top: -30px;
  }

  .citrus {
    top: 0;
    right: -160px;
    background-color: rgba(#0E1132,0.1);
  }

  .section__cta {
    background-color: #3398f3;

    &:hover {
      background-color: darken(#3398f3, 5);
    }

    .arrow path {
      fill: #ffffff;
    }

    &:after {
      background-color: #3398f3;
    }

  }

}